<template>
  <b-container>
    <b-tabs
        vertical
        nav-wrapper-class="col-3"
        nav-class="mt-5"
        v-model="tabIndex"
        @input="scrollToTop"
        lazy
    >
      <template #tabs-start>
        <b-button
            v-b-modal.modal-component-form class="green-button-transparent mr-3 mb-3 border-0"
        >
          <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
          ></b-icon>
          {{ $t('formManagement.createFormItem') }}
        </b-button>
        <modal-add-new-form-item
            class="d-inline-block mb-3"
            @create-form-item="createFormItem"
        ></modal-add-new-form-item>
      </template>
      <template #empty>
        <div class="mt-5" v-if="allowedToCreateForms">
          <p class="text-center" v-html="$t('formManagement.howToFormDesc')"></p>
        </div>
        <div class="mt-5" v-else>
          {{ $t('formManagement.emptyForm') }}
        </div>
      </template>
      <b-tab v-for="(formItem, index) in formItems" :key="index" lazy>
        <template v-slot:title>
          {{ formItem.name }}
        </template>
        <form-item-list-view
            class="mt-5"
            :userId="userId"
            :formItem="formItem"
            :formItemId="formItem.form_id"
            :formIndex="index"
            @changeInFormList="updateFormItemQuery"
        ></form-item-list-view>
      </b-tab>
    </b-tabs>
<!--<div v-else class="pt-5">&ndash;&gt;
      <div v-if="!$apollo.loading">&ndash;&gt;
        <p class="text-center" v-html="$t('teamManagement.notAllowed')"></p>&ndash;&gt;
      </div>&ndash;&gt;
    </div>&ndash;&gt;-->
  </b-container>
</template>

<script>
import CreateForm from '@/graphQlQueries/mutations/forms/createForm';
// import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import UserAtLeastManagerInOneGroup from '@/graphQlQueries/queries/accessCheckForManagementAreas';
import GetForms from '@/graphQlQueries/queries/forms/getForms';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';

import Auth from '@aws-amplify/auth';

export default {
  name: 'Form',
  components: {
    FormItemListView: () => import('@/components/settings/forms/FormItemListView.vue'),
    ModalAddNewFormItem: () => import('@/components/modals/forms/ModalAddNewFormItem.vue'),
  },
  data: () => ({
    pageType: 'form-settings',
    allowedToCreateForms: false,
    newOrChangedFormId: null,
    showAddNewFormItemModal: false,
    showEditTeamModal: false,
    userId: 1,
    user: null,
    pageId: [],
    formItems: [],
    number: 342,
    tabIndex: 0,
  }),
  async created() {
    const currentSession = await Auth.currentSession();
    const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
    this.isSysAdmin = idTokenPayload['x-hasura-allowed-roles'].includes('admin');
    this.userId = (idTokenPayload['x-hasura-user-id'])
      ? Math.floor(idTokenPayload['x-hasura-user-id'])
      : null;
    if (this.userId) {
      // check if the user is admin
      if (idTokenPayload['x-hasura-allowed-roles'].includes('admin')) {
        this.$currentUserRole = 'admin';
      } else {
        this.$currentUserRole = 'user';
      }
    }
    this.userEmail = Auth.user.attributes.email;
    const {
      data: {
        users: [user],
      },
    } = await this.$apollo.query({
      query: GetUserByEmail,
      variables: {
        email: this.userEmail,
      },
    });
    this.user = {
      userId: this.userId,
      name: user.full_name || null,
      email: Auth.user.attributes.email,
      isSysAdmin: user.sys_admin,
    };
    await this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: this.pageType,
      },
    });
    await this.$apollo.mutate({
      mutation: SetRunBreadcrumbQueryInCache,
      variables: {
        runQuery: true,
      },
    });
    // this mutation is used to display the header of the application
    await this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  apollo: {
    allowedToCreateForms: {
      query: UserAtLeastManagerInOneGroup,
      update(data) {
        return data.checkAccessToManagementAreas;
      },
    },
    formItems: {
      query: GetForms,
      update(data) {
        const formObjects = [];
        if (data.getForms) {
          data.getForms.forEach((formItem) => {
            const element = formItem;
            formObjects.push(element);
          });
        }

        formObjects.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        if (this.newOrChangedFormId !== null) {
          const newOrChangedFormIndex = formObjects.findIndex(
            (form) => form.form_id === this.newOrChangedFormId,
          );
          if (newOrChangedFormIndex !== -1) {
            // this is a workaround to update the active tab on create a new team
            // to respect the update cycle of the b-tabs
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$nextTick(() => {
                  this.$nextTick(() => {
                    requestAnimationFrame(() => {
                      this.tabIndex = newOrChangedFormIndex;
                      this.newOrChangedFormId = null;
                    });
                  });
                });
              });
            });
          }
        }
        return formObjects;
      },
      skip() {
        return !this.userId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async createFormItem(formObject) {
      console.log(formObject);
      try {
        const {
          data: {
            createForm: {
              newFormId,
            },
          },
        } = await this.$apollo.mutate({
          mutation: CreateForm,
          variables: {
            obj: {
              name: formObject.name,
              description: formObject.desc,
              owner_id: this.userId,
            },
          },
        });
        this.newOrChangedFormId = newFormId;
        this.number += 1;
        await this.$apollo.queries.formItems.refresh();
      } catch (ex) {
        console.error(ex);
      }
    },
    async updateFormItemQuery(formItemId) {
      if (formItemId === -1) {
        this.tabIndex = 0;
      } else {
        this.newOrChangedFormId = formItemId;
      }
      await this.$apollo.queries.formItems.refresh();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
</style>
